import React from 'react';
import ReactDOM from 'react-dom/client';
import Root from './Root';
import App from './components/App';

const el = document.querySelector('#root');
const root = ReactDOM.createRoot(el);

root.render(
    <Root>
        <App />
    </Root>
);