// https://github.com/duskload/react-device-detect/blob/master/src/components/withOrientationChange.js

import React from "react";

export function withMediaDetect(WrappedComponent) {
    return class extends React.Component {

        #isEventListenerAdded = false;
        #isMobile = undefined;

        constructor(props) {
            super(props);

            this.handleMediaChange = this.handleMediaChange.bind(this);
            this.onMediaChange = this.onMediaChange.bind(this);
            this.onPageLoad = this.onPageLoad.bind(this);

            this.state = {
                toggleUpdate: false
            };
        }

        handleMediaChange() {

            if (!this.#isEventListenerAdded) {
                this.#isEventListenerAdded = true;
            }

            const isMobile = window.innerWidth <= 768 ? true : false;
            if (isMobile !== this.#isMobile) {
                this.#isMobile = isMobile;
                this.setState({
                    toggleUpdate: !this.state.toggleUpdate
                })
            }
        }

        onMediaChange() {
            this.handleMediaChange();
        }

        onPageLoad() {
            this.handleMediaChange();
        }

        componentDidMount() {
                    
            if (typeof window !== 'undefined') {
                if (!this.#isEventListenerAdded) {
                    this.handleMediaChange();
                    window.addEventListener("load", this.onPageLoad, false);
                } else {
                    window.removeEventListener("load", this.onPageLoad, false);
                }

                window.addEventListener("resize", this.onMediaChange, false);
            }
        }

        componentWillUnmount() {
            window.removeEventListener("resize", this.onMediaChange, false);
        }

        render() {

            if(this.#isMobile === undefined && this.props.jsshop.is_mobile !== undefined) {
                this.#isMobile = this.props.jsshop.is_mobile;
            }          

            if (!this.props.jsshop || JSON.stringify(this.props.jsshop) === "{}") {
                return <WrappedComponent
                    {...this.props}
                />;
            }

            return <WrappedComponent
                {...this.props}
                mobile={this.#isMobile}
            />;
        }
    };
}