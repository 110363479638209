import * as actionTypes from "../actions/types";

export default function cmsReducer(state = {}, action) {
    var jscms = { ...state };
    switch (action.type) {
        case actionTypes.FETCH_JSCMS:
            return { ...state, ...action.payload };
        case actionTypes.FETCH_JSCMS_RESET_CONTENT:
            jscms.page = action.payload;
            return { ...state, ...jscms };
        case actionTypes.FETCH_JSCMS_CONTENT:
            jscms.page = action.payload;
            return { ...state, ...jscms };
        case actionTypes.FETCH_ERROR_JSSHOP:
            if (action.payload.hasError) {
                jscms.hasError = action.payload.hasError;
                jscms.errors = action.payload.errors;
                jscms.update = action.payload.update;
            }
            return { ...state, ...jscms };
        default:
            return state;
    }
}