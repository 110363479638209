import * as actionTypes from "../actions/types";

const checkoutProcessReducer = (state=[], action) => {   
    switch(action.type) {
        case actionTypes.FETCH_CHECKOUT_PROCESS:
            return [...state, ...action.payload];
        default:
            return state;
    }
}

export default checkoutProcessReducer;