import * as actionTypes from "../actions/types";

const formReducer = (state = {}, action) => {
    var jsform = { ...state };
    switch (action.type) {
        case actionTypes.FETCH_FORM:
            return { ...state, ...action.payload };
        case actionTypes.FETCH_RESET_FORM:
            jsform.fields = jsform.fields.map(field => {
                field.errors = action.payload;
                return field;               
            });
            return { ...state, ...jsform };
        case actionTypes.FETCH_UPDATE_FORM:
            return { ...state, ...action.payload };
        default:
            return state;
    }
}

export default formReducer;