import * as actionTypes from "../actions/types";

const notificationsReducer = (state={}, action) => { 
    var jsnotifications = { ...state };  
    switch(action.type) {
        case actionTypes.FETCH_NOTIFICATIONS:
            return {...state, ...action.payload};
        case actionTypes.FETCH_RESET_NOTIFICATIONS:
            jsnotifications.error = 
            jsnotifications.warning = 
            jsnotifications.success = 
            jsnotifications.info = action.payload;
            return { ...state, ...jsnotifications };
        default:
            return state;
    }
}

export default notificationsReducer;