import * as actionTypes from "../actions/types";

export default function jsShopReducer(state = {}, action) {
    var jsshop = { ...state };
    switch (action.type) {
        case actionTypes.FETCH_JSSHOP:
            return {...state, ...action.payload};
        case actionTypes.FETCH_ERROR_JSSHOP:
            jsshop = action.payload;
            return { ...state, ...jsshop };
        default:
            return state;
    }
}