import { combineReducers } from 'redux';

import jsShopReducer from './jsShopReducer';
import categoryTreeReducer from './categoryTreeReducer';
import categoryReducer from './categoryReducer';
import productReducer from './productReducer';
import cmsReducer from './cmsReducer';
import customerReducer from './customerReducer';
import formReducer from './formReducer';
import notificationsReducer from './notificationsReducer';
import routesReducer from './routesReducer';
import checkoutReducer from './checkoutReducer';
import checkoutProcessReducer from './checkoutProcessReducer';
import shoppingCartReducer from './shoppingCartReducer';
import jsOrderHistory from './jsOrderHistoryReducer';

export default combineReducers({
    jsshop: jsShopReducer,
    tree: categoryTreeReducer,
    jscategories: categoryReducer,
    jsproducts: productReducer,
    jscms: cmsReducer,
    customer: customerReducer,
    form: formReducer,
    notifications: notificationsReducer,
    routes: routesReducer,
    checkout: checkoutReducer,
    checkoutprocess: checkoutProcessReducer,
    shoppingcart: shoppingCartReducer,
    jsorderhistory: jsOrderHistory,
});