import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import reduxThunk from 'redux-thunk';
import reducers from './reducers';

const Root = ({ children, initialState = {} }) => {
    const store = createStore(
        reducers, 
        initialState, 
        applyMiddleware(reduxThunk)
    );

    return (
        <Provider store={store}>
            {children}
        </Provider>
    )
}

export default Root; 