export const FETCH_JSSHOP = 'fetch_jsshop';
export const FETCH_ERROR_JSSHOP = 'fetch_error_jsshop';
export const FETCH_ROUTES = 'fetch_routes';

export const FETCH_JSPRODUCTS = 'fetch_jsproducts';

export const FETCH_JSCMS = 'fetch_jscms';
export const FETCH_JSCMS_CONTENT = 'fetch_jscms_content';
export const FETCH_JSCMS_RESET_CONTENT = 'fetch_jscms_reset_content';
export const FETCH_ERROR_JSCMS = 'fetch_error_jscms';

export const FETCH_NOTIFICATIONS = 'fetch_notifications';
export const FETCH_RESET_NOTIFICATIONS = 'fetch_reset_notifications';
export const FETCH_CUSTOMER = 'fetch_customer';

export const FETCH_FORM = 'fetch_form';
export const FETCH_UPDATE_FORM = 'fetch_update_form';
export const FETCH_RESET_FORM = 'fetch_reset_form';

export const FETCH_CHECKOUT = 'fetch_checkout';
export const FETCH_CHECKOUT_PROCESS = 'fetch_checkoutprocess';

export const FETCH_JSSHOPPING_CART = 'fetch_shoppingcart';
export const FETCH_CHANGE_JSSHOPPING_CART = 'fetch_change_shoppingcart';
export const FETCH_ERROR_JSSHOPPING_CART = 'fetch_error_shoppingcart';
export const FETCH_RESET_JSSHOPPING_CART = 'fetch_reset_shoppingcart';
export const FETCH_UPDATE_JSSHOPPING_CART = 'fetch_update_shoppingcart';

export const FETCH_JSORDER_HISTORY = 'fetch_jsorder_history';