
class ObjectRoute 
{
    rule;
    url;
    label;

    constructor(rule = "", url = "", label = "") {
        this.rule = rule;
        this.url = url;
        this.label = label;
    }

    getRule = () => {
        return this.rule;
    }
}

export const getRoute = (routeName = "", routes, params = [], baseUrl = "") => {
 
    const objRoute = new ObjectRoute();
    if(!routes) {
        return objRoute;
    }

    const route = Object.keys(routes).includes(routeName) ? routes[routeName] : null;
    if (route) {

        if(params.length === 0) {
            objRoute.rule = baseUrl + route.rule;
            objRoute.url = baseUrl + route.rule;
            objRoute.label = route.label;
            return objRoute;
        }

        let url = route.rule.replaceAll("?", "");
        const keywords = (keyword, index) => {
            url = url.replace(':' + keyword, params[index]);
        };
        route.keywords.forEach(keywords);

        objRoute.rule = route.rule;
        objRoute.url = baseUrl + url;
        objRoute.label = route.label;
    }
  
    return objRoute;
}

export const urlLastCategory = (jsshop, jsroutes, jscategories) => {

    var url = '/';

    if (!jsshop || !jsroutes || !jscategories) {
        return url;
    }

    const { base_url } = jsshop.urls;

    const idCategory = jscategories.last_category_id;
    if (idCategory > 0) {
        const category = jscategories.categories[idCategory];
        if(category && category !== undefined) {
            const params =[category.id, category.link];
            const routeBack = getRoute('category', jsroutes, params, base_url);
            url = routeBack.url;
        }
    }

    return url;
}