import * as actionTypes from "../actions/types";
const productReducer = (state = [], action) => {
    switch (action.type) {
        case actionTypes.FETCH_JSPRODUCTS:
            return { ...state, ...action.payload };
        default:
            return state;
    }
}

export default productReducer;