import * as actionTypes from "../actions/types";

const checkoutReducer = (state={}, action) => {   
    switch(action.type) {
        case actionTypes.FETCH_CHECKOUT:
            return {...state, ...action.payload} ;
        default:
            return state;
    }
}

export default checkoutReducer;