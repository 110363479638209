import * as actionTypes from "../actions/types";

const customerReducer = (state={}, action) => {
    
    switch(action.type) {
        case actionTypes.FETCH_CUSTOMER:
            return {...state, ...action.payload} ;
        default:
            return state;
    }
}

export default customerReducer;