const categoryTreeReducer = (state=[], action) => {
    
    switch(action.type) {
        case 'FETCH_CATEGORYTREE':
            return [...state, ...action.payload];
        default:
            return state;
    }
}

export default categoryTreeReducer;